import React from "react";
import Jobs from "../../components/jobs";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import cms from "../../components/cms/cms";

const RemotePage = () => cms.keyword === "Remote" ? // TODO Find better solution
  <Layout>
    <SEO
      keywords={[`${cms.keyword} jobs`, `${cms.keyword} developer jobs`, `${cms.keyword} software engineering jobs`]}
      title={cms.description}
      description={`Looking for a ${cms.keyword} Developer job? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs.`}
    />
    <Jobs />
  </Layout> : <Layout>
    <SEO
      keywords={[`remote ${cms.keyword} jobs`, `remote ${cms.keyword} developer jobs`, `remote ${cms.keyword} software engineering jobs`]}
      title={`Remote ${cms.keyword} Developer Jobs`}
      description={`Looking for a remote ${cms.keyword} Developer job? ${cms.siteUrl} is exclusively dedicated to ${cms.keyword} jobs. Find remote ${cms.keyword} jobs anywhere in the world.`}
    />
    <Jobs remoteProp={"remote"} />
  </Layout>

export default RemotePage;